import React, { useState } from "react";
import { Card, CardImg, CardBody, CardTitle, CardText, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import img2 from './Assets/img/img2.jpg';
import img3 from './Assets/img/img3.jpg';
import img4 from './Assets/img/img4.jpg';
import img1682 from './Assets/img/img1682.jpg';
import img5 from './Assets/img/img5.jpg';
import img6 from './Assets/img/img6.jpg';
import img7 from './Assets/img/img7.jpg';
import img8 from './Assets/img/img8.jpg';
import img9 from './Assets/img/img9.jpg';
import img10 from './Assets/img/img10.jpg';
import img11 from './Assets/img/img11.jpg';
import img12 from './Assets/img/img12.jpg';
import img13 from './Assets/img/img13.jpg';
import img14 from './Assets/img/img14.jpg';
import img15 from './Assets/img/img15.jpg';
import img16 from './Assets/img/img16.jpg';
import img17 from './Assets/img/img17.jpg';
import img18 from './Assets/img/img18.jpg';
import img19 from './Assets/img/img19.jpg';
import img20 from './Assets/img/img20.jpg';
import img21 from './Assets/img/img21.jpg';
import img22 from './Assets/img/img22.jpg';
import img23 from './Assets/img/img23.jpg';
import img24 from './Assets/img/img24.jpg';
import img25 from './Assets/img/img25.jpg';
import img26 from './Assets/img/img26.jpg';
import img27 from './Assets/img/img27.jpg';
import img28 from './Assets/img/img28.jpg';
import img29 from './Assets/img/img29.jpg';
import img30 from './Assets/img/img30.jpg';
import img31 from './Assets/img/img31.jpg';
import img32 from './Assets/img/img32.jpg';
import img33 from './Assets/img/img33.jpg';
import img34 from './Assets/img/img34.jpg';
import img35 from './Assets/img/img35.jpg';
import img36 from './Assets/img/img36.jpg';
import img37 from './Assets/img/img37.jpg';
import img38 from './Assets/img/img38.jpg';
import img39 from './Assets/img/img39.jpg';
import img40 from './Assets/img/img40.jpg';
import img41 from './Assets/img/img41.jpg';
import img42 from './Assets/img/img42.jpg';
import img43 from './Assets/img/img43.jpg';
import img44 from './Assets/img/img44.jpg';
import img45 from './Assets/img/img45.jpg';
import img46 from './Assets/img/img46.jpg';
import img47 from './Assets/img/img47.jpg';
import img48 from './Assets/img/img48.jpg';
import img49 from './Assets/img/img49.jpg';
import img50 from './Assets/img/img50.jpg';
import img51 from './Assets/img/img51.jpg';
import img52 from './Assets/img/img52.jpg';
import img53 from './Assets/img/img53.jpg';
import img54 from './Assets/img/img54.jpg';
import img55 from './Assets/img/img55.jpg';
import img56 from './Assets/img/img56.jpg';
import img57 from './Assets/img/img57.jpg';
import img58 from './Assets/img/img58.jpg';
import img59 from './Assets/img/img59.jpg';
import img60 from './Assets/img/img60.jpg';
import img61 from './Assets/img/img61.jpg';
import img62 from './Assets/img/img62.jpg';
import img63 from './Assets/img/img63.jpg';
import img64 from './Assets/img/img64.jpg';
import img65 from './Assets/img/img65.jpg';
import img66 from './Assets/img/img66.jpg';
import img67 from './Assets/img/img67.jpg';
import img68 from './Assets/img/img68.jpg';
import img69 from './Assets/img/img69.jpg';
import img70 from './Assets/img/img70.jpg';
import img71 from './Assets/img/img71.jpg';
import img72 from './Assets/img/img72.jpg';
import img73 from './Assets/img/img73.jpg';
import img74 from './Assets/img/img74.jpg';
import img75 from './Assets/img/img75.jpg';
import img76 from './Assets/img/img76.jpg';
import img77 from './Assets/img/img77.jpg';
import img78 from './Assets/img/img78.jpg';
import img79 from './Assets/img/img79.jpg';
import img80 from './Assets/img/img80.jpg';
import img81 from './Assets/img/img81.jpg';
import img82 from './Assets/img/img82.jpg';
import img83 from './Assets/img/img83.jpg';
import img84 from './Assets/img/img84.jpg';
import img85 from './Assets/img/img85.jpg';
import img86 from './Assets/img/img86.jpg';
import img87 from './Assets/img/img87.jpg';
import img88 from './Assets/img/img88.jpg';
import img89 from './Assets/img/img89.jpg';
import img90 from './Assets/img/img90.jpg';
import img91 from './Assets/img/img91.jpg';
import img92 from './Assets/img/img92.jpg';
import img93 from './Assets/img/img93.jpg';
import img94 from './Assets/img/img94.jpg';
import img95 from './Assets/img/img95.jpg';
import img96 from './Assets/img/img96.jpg';
import img97 from './Assets/img/img97.jpg';

const ImageGallery = () => {
  const[imageData, setImageData] = useState([
    {
      imgSrc: img2,
      name: '1731',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img3,
      name: '1727',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img4,
      name: '1689',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img1682,
      name: '1682',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img5,
      name: '1619',
      rate: '51 rs dzn',
    },    {
      imgSrc: img6,
      name: '1198',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img7,
      name: '1681',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img8,
      name: '1690',
      rate: '51 rs dzn',
    },    {
      imgSrc: img9,
      name: '1691',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img10,
      name: '1593',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img11,
      name: '1692',
      rate: '51 rs dzn',
    },    {
      imgSrc: img12,
      name: '1724',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img13,
      name: '1722',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img14,
      name: '1726',
      rate: '51 rs dzn',
    },    {
      imgSrc: img15,
      name: '1728',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img16,
      name: '1730',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img17,
      name: '1729',
      rate: '36 rs dzn',
    },  
    {
      imgSrc: img18,
      name: '1733',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img19,
      name: '1735',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img20,
      name: '1734',
      rate: '51 rs dzn',
    },    {
      imgSrc: img21,
      name: '1702',
      rate: '57 rs dzn',
    },
    {
      imgSrc: img22,
      name: '1701',
      rate: '63 rs dzn',
    },
    {
      imgSrc: img23,
      name: '1700',
      rate: '72 rs dzn',
    },    {
      imgSrc: img24,
      name: '1742',
      rate: '42 rs dzn',
    },
    {
      imgSrc: img25,
      name: '1743',
      rate: '42 rs dzn',
    },
    {
      imgSrc: img26,
      name: '1686',
      rate: '51 rs dzn',
    },  
    {
      imgSrc: img27,
      name: '1582',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img28,
      name: '1500',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img29,
      name: '1685',
      rate: '45 rs dzn',
    }, 
    {
      imgSrc: img30,
      name: '1683',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img31,
      name: '1751',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img32,
      name: '1032',
      rate: '51 rs dzn',
    },    {
      imgSrc: img33,
      name: '271',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img34,
      name: '1508',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img35,
      name: '1500',
      rate: '51 rs dzn',
    },    {
      imgSrc: img36,
      name: '272',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img37,
      name: '1151',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img38,
      name: '1162',
      rate: '51 rs dzn',
    },    {
      imgSrc: img39,
      name: '213',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img40,
      name: '1296',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img41,
      name: '265',
      rate: '45 rs dzn',
    },    {
      imgSrc: img42,
      name: '1305',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img43,
      name: '1005',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img44,
      name: '270',
      rate: '48 rs dzn',
    },    {
      imgSrc: img45,
      name: '1299',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img46,
      name: '1159',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img47,
      name: '1044',
      rate: '51 rs dzn',
    },    {
      imgSrc: img48,
      name: '323',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img49,
      name: '1499',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img50,
      name: '325',
      rate: '45 rs dzn',
    },    
    {
      imgSrc: img51,
      name: '1519',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img52,
      name: '1494',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img53,
      name: '505',
      rate: '51 rs dzn',
    },    
    {
      imgSrc: img54,
      name: '1590',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img55,
      name: '1618',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img56,
      name: '1681',
      rate: '51 rs dzn',
    },    {
      imgSrc: img57,
      name: '1589',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img58,
      name: '265',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img59,
      name: '1635',
      rate: '66 rs dzn',
    },    {
      imgSrc: img60,
      name: '1619',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img61,
      name: '1588',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img62,
      name: '1583',
      rate: '51 rs dzn',
    },    {
      imgSrc: img63,
      name: '1686',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img64,
      name: '1592',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img65,
      name: '1044',
      rate: '51 rs dzn',
    },    {
      imgSrc: img66,
      name: '1573',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img67,
      name: '1393',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img68,
      name: '1593',
      rate: '51 rs dzn',
    },    {
      imgSrc: img69,
      name: '1500',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img70,
      name: '1519',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img71,
      name: '1499',
      rate: '51 rs dzn',
    },    {
      imgSrc: img72,
      name: '1658',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img73,
      name: '1582',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img74,
      name: '1594',
      rate: '51 rs dzn',
    },    {
      imgSrc: img75,
      name: '1283',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img76,
      name: '36',
      rate: '45 rs dzn',
    },
    {
      imgSrc: img77,
      name: '1163',
      rate: '51 rs dzn',
    },    {
      imgSrc: img78,
      name: '1100',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img79,
      name: '1283',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img80,
      name: '1332',
      rate: '51 rs dzn',
    },    {
      imgSrc: img81,
      name: '1270',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img82,
      name: '1439',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img83,
      name: '324',
      rate: '45 rs dzn',
    },    {
      imgSrc: img84,
      name: '1332',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img85,
      name: '946',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img86,
      name: '1746',
      rate: '51 rs dzn',
    },    {
      imgSrc: img87,
      name: '1431',
      rate: '39 rs dzn',
    },
    {
      imgSrc: img88,
      name: '1754',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img89,
      name: '1635',
      rate: '66 rs dzn',
    },    {
      imgSrc: img90,
      name: '1393',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img91,
      name: '1618',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img92,
      name: '1695',
      rate: '45 rs dzn',
    },    {
      imgSrc: img93,
      name: '1732',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img94,
      name: '1725',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img95,
      name: '1723',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img96,
      name: '1594',
      rate: '51 rs dzn',
    },
    {
      imgSrc: img97,
      name: '1494',
      rate: '51 rs dzn',
    },
  ]);


  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [editedName, setEditedName] = useState('');
  const [editedRate, setEditedRate] = useState('');

  const toggleModal = (action, index) => {
    setSelectedAction(action);

    if (action === 'edit') {
      const selectedImage = imageData[index];
      setEditedName(selectedImage.name);
      setEditedRate(selectedImage.rate);
    }

    setModal(!modal);
  };

  const handleAddToCart = (name) => {
    if (!cartItems.includes(name)) {
      setCartItems((prevCartItems) => [...prevCartItems, name]);
    }
  };

  const handleBuyClick = (index) => {
    setSelectedImage(index);
    toggleModal('buy');
  };

  const handleEditClick = (index) => {
    setSelectedImage(index);
    toggleModal('edit', index);
  };

  const handleAddClick = (index) => {
    setSelectedImage(index);
    toggleModal('add');
  };

  const handleDeleteClick = (index) => {
    setSelectedImage(index);
    toggleModal('delete');
  };

  const handleConfirmDelete = () => {
    const itemName = imageData[selectedImage]?.name;
    setCartItems((prevCartItems) => prevCartItems.filter(item => item !== itemName));
    toggleModal(null);
  };

  const handleConfirmEdit = () => {
    const updatedImageData = [...imageData];
    updatedImageData[selectedImage] = {
      ...updatedImageData[selectedImage],
      name: editedName,
      rate: editedRate,
    };
    setImageData(updatedImageData);

    toggleModal(null);
  };

  const handleTopItemNameClick = (itemName) => {
    const index = imageData.findIndex(item => item.name === itemName);
    if (index !== -1) {
      setSelectedImage(index);
      toggleModal('delete');
    }
  };

  const handleRemoveFromCart = (itemName) => {
    setCartItems((prevCartItems) => prevCartItems.filter(item => item !== itemName));
  };

  const cardStyle = (index) => ({
    backgroundColor: 'blue',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    transform: index === hoveredIndex ? 'scale(1.05)' : 'scale(1)',
  });

  const textStyle = {
    fontSize: '25px',
    color: 'white',
  };

  const cardImgStyle = {
    height: '430px',
    objectFit: 'cover',
  };

  return (
    <div>
      <h2 className="mb-4">---------- * OUR BUSINESS PRODUCTS ARE * ----------</h2>
      <div>
        <h3>Cart Items:</h3>
        <ul>
          {cartItems.map((item, index) => (
            <li key={index} className="mb-2">
              <span>
                {item}
                <Button color="danger" size="sm" className="ml-2" onClick={() => handleRemoveFromCart(item)}>
                  Delete
                </Button>
              </span>
              <span className="ml-3" onClick={() => handleTopItemNameClick(item)}>
                {/* Add 10px of space between the item name and delete button */}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <Row>
        {imageData.map((item, index) => (
          <Col
            key={index}
            sm="6"
            md="3"
            className="mb-4"
            onMouseOver={() => setHoveredIndex(index)}
            onMouseOut={() => setHoveredIndex(null)}
          >
            <Card
              style={cardStyle(index)}
            >
              <CardImg top width="100%" src={item.imgSrc} alt={item.name} style={cardImgStyle} />
              <CardBody>
                <CardTitle style={textStyle}>{item.name}</CardTitle>
                <CardText style={textStyle}>{item.rate}</CardText>
                <Button color="success" onClick={() => handleAddToCart(item.name)}>
                  Add to Cart
                </Button>
                {' '}
                <Button color="info" onClick={() => handleEditClick(index)}>
                  Edit
                </Button>
                {' '}
                <Button color="primary" onClick={() => handleBuyClick(index)}>
                  Buy
                </Button>
                {' '}
                <Button color="danger" onClick={() => handleDeleteClick(index)}>
                  Delete
                </Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>
          {selectedAction === 'buy' && 'Buy Image'}
          {selectedAction === 'edit' && 'Edit Image'}
          {selectedAction === 'add' && 'Add Image'}
          {selectedAction === 'delete' && 'Delete Image'}
        </ModalHeader>
        <ModalBody>
          {selectedAction === 'buy' && (
            <p>Select payment method to buy image {imageData[selectedImage]?.name}.
              <li>Net-Banking</li><br></br>
              <li>Credit-Card</li><br></br>
              <li>Paytem</li><br></br>
              <li>Phone Pay</li><br></br>
              <li>Google pay</li><br></br>
              <li>Amazon Pay</li><br></br>
              <li>Paypal</li><br></br>
            </p>
          )}
          {selectedAction === 'edit' && (
            <>
              <p>Edit details for image {imageData[selectedImage]?.name}.</p>
              <label htmlFor="editedName">Name:</label>
              <input
                type="text"
                id="editedName"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
              />
              <br />
              <label htmlFor="editedRate">Rate:</label>
              <input
                type="text"
                id="editedRate"
                value={editedRate}
                onChange={(e) => setEditedRate(e.target.value)}
              />
            </>
          )}
          {selectedAction === 'add' && (
            <p>Add new image with details.</p>
          )}
          {selectedAction === 'delete' && (
            <p>Are you sure you want to delete image {imageData[selectedImage]?.name}?</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            if (selectedAction === 'buy') {
              // Handle Buy Now click
            } else if (selectedAction === 'edit') {
              // Handle Save Changes click
              handleConfirmEdit();
            } else if (selectedAction === 'add') {
              // Handle Add Image click
            } else if (selectedAction === 'delete') {
              // Handle Delete click
              handleConfirmDelete();
            }
            toggleModal(null);
          }}>
            {selectedAction === 'buy' && 'Buy Now'}
            {selectedAction === 'edit' && 'Save Changes'}
            {selectedAction === 'add' && 'Add Image'}
            {selectedAction === 'delete' && 'Delete'}
          </Button>{' '}
          <Button color="secondary" onClick={() => toggleModal(null)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImageGallery;